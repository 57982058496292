// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__myEducation__eImage__N4T84{width:100%;max-width:240px}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/components/MyEducation/styles.scss"],"names":[],"mappings":"AAGE,oCACE,UAAA,CAEA,eAAA","sourcesContent":["@import '~@portfolio-app/styles/src/properties/spacing';\n\n.myEducation {\n  &__eImage {\n    width: 100%;\n\n    max-width: $base * 60;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myEducation__eImage": `styles__myEducation__eImage__N4T84`
};
export default ___CSS_LOADER_EXPORT___;
