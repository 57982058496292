// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__CvBlock_buttons__ecIyh{width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/components/PersonalInfo/components/CVBlock/styles.scss"],"names":[],"mappings":"AAGE,gCACE,UAAA","sourcesContent":["@import '~@portfolio-app/styles/src/properties/sizes';\n\n.CvBlock {\n  &_buttons {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CvBlock_buttons": `styles__CvBlock_buttons__ecIyh`
};
export default ___CSS_LOADER_EXPORT___;
