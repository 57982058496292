// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__contactLink__contact__HPCfl{display:flex;align-items:center}.styles__contactLink__contactIcon__PTTs2{margin-right:4px}`, "",{"version":3,"sources":["webpack://./src/components/ContactLink/styles.scss","webpack://./../../libraries/styles/src/properties/sizes.scss"],"names":[],"mappings":"AAGE,qCACE,YAAA,CAEA,kBAAA,CAGF,yCACE,gBCRG","sourcesContent":["@import '~@portfolio-app/styles/src/properties/spacing';\n\n.contactLink {\n  &__contact {\n    display: flex;\n\n    align-items: center;\n  }\n\n  &__contactIcon {\n    margin-right: $space_2xs;\n  }\n}\n","$baseRem: 4px;\n\n$base: 4px;\n\n$fontBase: $base * 2;\n\n// fonts\n$font_xs: $fontBase;\n$font_s: $fontBase * 2;\n$font_m: $fontBase * 3;\n$font_l: $fontBase * 4;\n$font_xl: $fontBase * 5;\n\n\n// breakpoints \n\n$breakpoint-mobile: 375px;\n$breakpoint-tablet: 768px;\n$breakpoint-desktop-s: 1024px;\n$breakpoint-desktop-m: 1440px;\n$breakpoint-desktop-l: 1770px;\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactLink__contact": `styles__contactLink__contact__HPCfl`,
	"contactLink__contactIcon": `styles__contactLink__contactIcon__PTTs2`
};
export default ___CSS_LOADER_EXPORT___;
