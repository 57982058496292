// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__mobileMenu__y95pj{display:flex;justify-content:space-between}.styles__mobileMenu__logo__MNOfH{display:flex}`, "",{"version":3,"sources":["webpack://./src/components/Layout/components/MobileMenu/styles.scss"],"names":[],"mappings":"AAAA,2BACE,YAAA,CACA,6BAAA,CAEA,iCACE,YAAA","sourcesContent":[".mobileMenu {\n  display: flex;\n  justify-content: space-between;\n\n  &__logo {\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileMenu": `styles__mobileMenu__y95pj`,
	"mobileMenu__logo": `styles__mobileMenu__logo__MNOfH`
};
export default ___CSS_LOADER_EXPORT___;
