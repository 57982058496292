// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__logo__qQRsS{display:block;width:200px;height:64px;background:url("https://gnikitin.ru/files/logo.png") 0 50% no-repeat;background-size:100%}`, "",{"version":3,"sources":["webpack://./src/components/Logo/styles.scss"],"names":[],"mappings":"AAEA,qBACE,aAAA,CAEA,WAAA,CACA,WAAA,CAEA,oEAAA,CACA,oBAAA","sourcesContent":["@import '~@portfolio-app/styles/src/properties/sizes';\n\n.logo {\n  display: block;\n\n  width: $base * 50;\n  height: $base * 16;\n\n  background: url('https://gnikitin.ru/files/logo.png') 0 50% no-repeat;\n  background-size: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `styles__logo__qQRsS`
};
export default ___CSS_LOADER_EXPORT___;
