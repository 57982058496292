// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__acbInfo_card__kluW5{display:flex}.styles__acbInfo_card__kluW5 img{max-width:200px}.styles__acbInfo_buttons__G9wHY{display:flex;width:100%;height:100%}.styles__acbInfo_buttonsWrapper__Etlv2{display:flex;flex-direction:row}.styles__acbInfo_gitIcon__t06Eo{display:flex}.styles__acbInfo_gitIcon__t06Eo svg{width:40px;height:40px}@media screen and (max-width: 960px){.styles__acbInfo_card__kluW5{flex-direction:column;align-items:center}.styles__acbInfo_buttonsWrapper__Etlv2{display:flex;flex-direction:column}}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/components/PersonalInfo/components/AgileCatBotInfo/styles.scss"],"names":[],"mappings":"AAKE,6BACE,YAAA,CAEA,iCACE,eAAA,CAIJ,gCACE,YAAA,CACA,UAAA,CACA,WAAA,CAGF,uCACE,YAAA,CACA,kBAAA,CAGF,gCACE,YAAA,CAEA,oCACE,UAAA,CACA,WAAA,CAKN,qCAEI,6BACE,qBAAA,CACA,kBAAA,CAGF,uCACE,YAAA,CACA,qBAAA,CAAA","sourcesContent":["@import '~@portfolio-app/styles/src/properties/sizes';\n\n$max-width: 960px;\n\n.acbInfo {\n  &_card {\n    display: flex;\n\n    img {\n      max-width: 200px;\n    }\n  }\n\n  &_buttons {\n    display: flex;\n    width: 100%;\n    height: 100%;\n  }\n\n  &_buttonsWrapper {\n    display: flex;\n    flex-direction: row;\n  }\n\n  &_gitIcon {\n    display: flex;\n\n    svg {\n      width: $base * 10;\n      height: $base * 10;\n    }\n  }\n}\n\n@media screen and (max-width: $max-width) {\n  .acbInfo {\n    &_card {\n      flex-direction: column;\n      align-items: center;\n    }\n\n    &_buttonsWrapper {\n      display: flex;\n      flex-direction: column;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"acbInfo_card": `styles__acbInfo_card__kluW5`,
	"acbInfo_buttons": `styles__acbInfo_buttons__G9wHY`,
	"acbInfo_buttonsWrapper": `styles__acbInfo_buttonsWrapper__Etlv2`,
	"acbInfo_gitIcon": `styles__acbInfo_gitIcon__t06Eo`
};
export default ___CSS_LOADER_EXPORT___;
