import * as React from 'react';

export const GraphqlIcon = (props: { className?: string }) => (
  <div {...props}>
    <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em">
      <path
        fill="currentColor"
        d="M2.61 4.432l4.142-2.417-.504-.864-4.143 2.417.504.864zM2 9.5v-4H1v4h1zm6.248-7.485l4.143 2.417.504-.864-4.143-2.417-.504.864zM13 5.5v4h1v-4h-1zm-.252 4.86l-4.5 2.625.504.864 4.5-2.625-.504-.864zm-5.996 2.625l-4.143-2.417-.504.864 4.143 2.417.504-.864zM6.584 1.973l-5 7.5.832.554 5-7.5-.832-.554zm6.832 7.5l-5-7.5-.832.554 5 7.5.832-.554zM2.5 11h10v-1h-10v1zm5-9a.5.5 0 01-.5-.5H6A1.5 1.5 0 007.5 3V2zm.5-.5a.5.5 0 01-.5.5v1A1.5 1.5 0 009 1.5H8zM7.5 1a.5.5 0 01.5.5h1A1.5 1.5 0 007.5 0v1zm0-1A1.5 1.5 0 006 1.5h1a.5.5 0 01.5-.5V0zm6 5a.5.5 0 01-.5-.5h-1A1.5 1.5 0 0013.5 6V5zm.5-.5a.5.5 0 01-.5.5v1A1.5 1.5 0 0015 4.5h-1zm-.5-.5a.5.5 0 01.5.5h1A1.5 1.5 0 0013.5 3v1zm0-1A1.5 1.5 0 0012 4.5h1a.5.5 0 01.5-.5V3zm0 8a.5.5 0 01-.5-.5h-1a1.5 1.5 0 001.5 1.5v-1zm.5-.5a.5.5 0 01-.5.5v1a1.5 1.5 0 001.5-1.5h-1zm-.5-.5a.5.5 0 01.5.5h1A1.5 1.5 0 0013.5 9v1zm0-1a1.5 1.5 0 00-1.5 1.5h1a.5.5 0 01.5-.5V9zm-6 5a.5.5 0 01-.5-.5H6A1.5 1.5 0 007.5 15v-1zm.5-.5a.5.5 0 01-.5.5v1A1.5 1.5 0 009 13.5H8zm-.5-.5a.5.5 0 01.5.5h1A1.5 1.5 0 007.5 12v1zm0-1A1.5 1.5 0 006 13.5h1a.5.5 0 01.5-.5v-1zm-6-1a.5.5 0 01-.5-.5H0A1.5 1.5 0 001.5 12v-1zm.5-.5a.5.5 0 01-.5.5v1A1.5 1.5 0 003 10.5H2zm-.5-.5a.5.5 0 01.5.5h1A1.5 1.5 0 001.5 9v1zm0-1A1.5 1.5 0 000 10.5h1a.5.5 0 01.5-.5V9zm0-4a.5.5 0 01-.5-.5H0A1.5 1.5 0 001.5 6V5zm.5-.5a.5.5 0 01-.5.5v1A1.5 1.5 0 003 4.5H2zM1.5 4a.5.5 0 01.5.5h1A1.5 1.5 0 001.5 3v1zm0-1A1.5 1.5 0 000 4.5h1a.5.5 0 01.5-.5V3z"
      />
    </svg>
  </div>
);
