// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__anchor__umbTb{position:relative;top:-80px;display:block;visibility:hidden}`, "",{"version":3,"sources":["webpack://./src/components/Anchor/styles.scss"],"names":[],"mappings":"AAAA,uBACE,iBAAA,CACA,SAAA,CAEA,aAAA,CAEA,iBAAA","sourcesContent":[".anchor {\n  position: relative;\n  top: -80px;\n\n  display: block;\n\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"anchor": `styles__anchor__umbTb`
};
export default ___CSS_LOADER_EXPORT___;
