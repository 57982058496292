// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__myExperience__webDevImage__fGENP{width:100%}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/components/MyExperience/styles.scss"],"names":[],"mappings":"AACE,0CACE,UAAA","sourcesContent":[".myExperience {\n  &__webDevImage {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myExperience__webDevImage": `styles__myExperience__webDevImage__fGENP`
};
export default ___CSS_LOADER_EXPORT___;
