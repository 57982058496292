import * as React from 'react';

export const Css3Icon = (props: { className?: string }) => (
  <div {...props}>
    <svg viewBox="0 0 16 16" fill="currentColor" height="1em" width="1em">
      <path
        fill="currentColor"
        d="M2.381.758l-.537 2.686h10.934l-.342 1.735H1.496l-.53 2.686h10.933l-.61 3.063-4.406 1.46-3.819-1.46.261-1.329H.639L0 12.823l6.316 2.417 7.281-2.417L16 .757z"
      />
    </svg>
  </div>
);
