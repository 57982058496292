// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__contactForm__iKAau{width:100%}.styles__contactForm__inputText__Px2c5{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/ContactForm/styles.scss"],"names":[],"mappings":"AAAA,4BACE,UAAA,CAEA,uCACE,UAAA","sourcesContent":[".contactForm {\n  width: 100%;\n\n  &__inputText {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactForm": `styles__contactForm__iKAau`,
	"contactForm__inputText": `styles__contactForm__inputText__Px2c5`
};
export default ___CSS_LOADER_EXPORT___;
