export const EN_LOCALE = {
  aboutMe: 'About me',
  myBlog: 'My blog',
  myProjects: 'Test tasks',
  contactMe: 'Contact me:',
  gitVerseProfile: 'GitVerse profile',
  telegramProfile: 'Telegram profile',
  mailMe: 'Mail me',
  vkMe: 'VK profile',
  greetingsHeader: 'Hello, My name is German',
  messageSended: 'Message successfully sended!',
  messageSendFail: 'Message not sended :(',
  footerContent: 'Nikitin German. Frontend developer.',
  aboutMeText:
    'Welcome to my personal portfolio application!\n' +
    'My name is Nikitin German and I am a senior frontend developer.\n \n' +
    'This website - a microservice single page application!' +
    'You can browse the source code of this application on my GitVerse account. This is monorepo.\n \n' +
    'Here you can check my development experience and contact with me if you are interested in my CV.' +
    'By the way, you can download it right here in any convenient format for you or print it in English or Russian languages.\n \n' +
    'I would appreciate any feedback. Have a nice day!',
  agileCatBotInfo:
    'Creator and lead maintainer of Agile Cat Bot.\nThis is online Telegram chat bot for organizing cross-review of Agile teams.',
  getAcb: 'Get Agile Cat Bot',
  checkAcb: 'Source code on GitVerse',
  downloadMyCv: 'Download my CV',
  printMyCv: 'Print my CV',
  birthdateText: 'Birthdate',
  birthDateInfo: 'Aug 4, 1994',
  locationText: 'Location',
  locationPlace: 'Saint-Petersburg, Russia',
  educationText: 'Education',
  educationInfo: 'ITMO University, Russia, 2010-2019',
  mySkills: 'My Skills',
  webDevelopment: 'Web Development',
  webDevelopmentDescription:
    'Website developing, SPA, Chat Bots. Work experience with foreign clients (UpWork/oDesk) and large\n' +
    'companies (Alfa Bank, VTB, Gazprom) in the Agile team. Remote work only.',
  webDevelopmentMainDirections: 'Main directions',
  webDevelopmentSkillReact: 'Development on React + Redux (SPA), Experience 6 years. (over 18 huge apps implemented)',
  webDevelopmentSkillLayout:
    'Website development on HTML5 / CSS3 / JavaScript + CMS WordPress / Joomla / Drupal / Bitrix from scratch.',
  webDevelopmentSkillIS: 'Development of enterprise security systems, threat analysis.',
  frontEndDevelopment: 'Frontend Development',
  frontEndDevelopmentDescription: 'Confident knowledge of modern methods and technologies of front-end development.',
  frontEndDevelopmentMainSkills: 'Main skills',
  frontEndDevelopmentSkillJS:
    'JavaScript and Typescript with modern specifications ES6-ES12, React, Redux (FLUX) + Async libs Saga/Thunk.' +
    'Deep understanding of the TypeScript language with advanced types, unions and selecting from it (type guards), conditional types, overloads, utility types including custom types',
  frontEndDevelopmentSkillHTML:
    'Advanced Layout: HTML, CSS with pre and postprocessors (LESS / SASS / PostCSS) + Flex/Grid',
  frontEndDevelopmentSkillWebpack:
    'Modern project assembly methods. WebPack 4/5 (with Module Federation) and experience with large monorepo (over 40+ projects with libs and assets)',
  mySkillsLevel: 'My skills level',
  myExperience: 'My Experience',
  myExperienceDescription1: 'I have been doing commercial development for over',
  myExperienceDescription2: 'years. I started by creating\nwebsites from scratch in web studios in 2014.',
  myExperienceDescription3:
    'For the last year i have been working with large companies in international projects' +
    '(VTB, Gazprom, Alfa Bank) in the development team (6-14 ppl).',
  workExperienceWebStudioPosition: 'Web Master',
  workExperienceWebStudioCompanyName: 'Exa-Media Web studio',
  workExperienceWebStudioDescription:
    'Development, refinement, configuration and technical support of sites (HTML5, javascript, php) users on various CMS.',

  workExperiencePrivateCompanyPosition: 'Web Developer',
  workExperiencePrivateCompanyCompanyName: 'iLocked inc.',
  workExperiencePrivateCompanyDescription:
    'Development, promotion and those. Support for the companys website and franchise projects. Development of a high-load project under a private CMS. Developing a website, contains all quests and quest companies around the country with ability to booking different quests. Stack: private cms(php/js) + HTML5/CSS3 (Less)',

  workExperienceMobilePosition: 'Mobile Web Developer',
  workExperienceMobileCompanyName: 'SHIFT Motors',
  workExperienceMobileDescription:
    'Development of a company website (html / php / javascript), participation in a startup: developing android application. Application topic: Security alarm button for private guard company. GPS tracking of rapid response team. Stack in Frontend: Knockout, HTML5, CSS3, LESS.',

  workExperienceIpPosition: 'Frontend Web Developer',
  workExperienceIpCompanyName: 'Individual entrepreneur Nikitin G.N.',
  workExperienceIpYears: '2017-2022',
  workExperienceIpDescription:
    'Website developing, SPA. Work experience with foreign clients (UpWork/oDesk) and large companies (Alfa Bank, VTB, Gazprom) in the Agile team. Working as IP (as a remote employee under a work contract). In the present time more than 8 projects implemented to production.',
  workExperienceCurrentPosition: 'Lead Front End Developer',
  workExperienceCurrentName: 'Orbita Center',
  workExperienceCurrentYears: '2022-Present time',
  workExperienceCurrentDescription:
    'Development of a microservice multi-platform application for customer service of a fintech company (large bank)',
  myEducation: 'My Education',
  myEducationUniversityFac: 'Secure Information Technologies',
  myEducationUniversityName: 'ITMO University, Russia',
  myEducationUniversityDescription:
    'Diploma topic: Development of a secure information environment for a commercial company',
  myEducationUniversityFac2: 'Software for Computer Hardware and Automated Systems',
  myEducationUniversityDescription2:
    'Diploma topic: Development of a software module for cataloging educational materials for university educational environment',
  myEducationCoursesFac: 'Pre-profile training courses',
  myEducationCoursesName: 'SEGRIS Institute of Information Technologies, Russia',
  sendMeMessage: 'Send me a message!',
  sendMeMessageDesc1: 'Are you going to create a greate app? \nI would like to help you with it!',
  sendMeMessageDesc2: 'Just send me a message and i will contact you ASAP. \n\nThank you ;)',
  formNameLabel: 'Your name',
  formEmailLabel: 'Your e-mail',
  formMessageLabel: 'Your message',
  formSubmitButtonText: 'Send message',
  projectsTimerName: 'Timer application',
  projectsTimerDescription:
    'Simple react timer with Material UI styles and components\n.' +
    'You can set timer interval, reset timer or pause it.',
  homeLink: 'Home',
  openProject: 'Open project',
  projectsTaskDashboard: 'DnD Task Dashboard',
  projectsTaskDashboardDescription:
    'Real task dashboard with drag and drop function, like Jira or Miro.\n' +
    'You can drag elements(tasks) between columns, but you can not disrupt the workflow. For example, you can drop "TODO" to "DEV", but not "TODO" to "RFT" or "PROD".',
  projectsUsersTable: 'Users Table',
  projectsUsersTableDescription:
    'Simple table of users. You can select one ore more users and it selected users are displayed at the bottom',
  projectsQuiz: 'Quiz application',
  projectQuizDescription: 'Real quiz with feedback and result at the end! Go through the Quiz and know you result!',
};
