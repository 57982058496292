// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__gridLayout__OdU6M{margin-top:10px}.styles__gridLayout__container__P80Zm{min-height:100%;padding-top:80px}.styles__NavBar__toolbar__H_b2A{justify-content:center}.styles__NavBar__mobileMenu__SOKuY{display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./src/components/Layout/styles.scss"],"names":[],"mappings":"AAIA,2BACE,eAAA,CAEA,sCACE,eAAA,CACA,gBAAA,CAKF,gCACE,sBAAA,CAGF,mCACE,YAAA,CACA,qBAAA","sourcesContent":["@import '~@portfolio-app/styles/src/mixins/font-mixins';\n@import '~@portfolio-app/styles/src/properties/sizes';\n@import '~@portfolio-app/styles/src/properties/spacing';\n\n.gridLayout {\n  margin-top: 10px;\n\n  &__container {\n    min-height: 100%;\n    padding-top: $base * 20;\n  }\n}\n\n.NavBar {\n  &__toolbar {\n    justify-content: center;\n  }\n\n  &__mobileMenu {\n    display: flex;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridLayout": `styles__gridLayout__OdU6M`,
	"gridLayout__container": `styles__gridLayout__container__P80Zm`,
	"NavBar__toolbar": `styles__NavBar__toolbar__H_b2A`,
	"NavBar__mobileMenu": `styles__NavBar__mobileMenu__SOKuY`
};
export default ___CSS_LOADER_EXPORT___;
