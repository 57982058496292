// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__AboutMe_anchor__UY0pc{top:-280px}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/components/PersonalInfo/components/AboutMe/styles.scss"],"names":[],"mappings":"AAGE,+BACE,UAAA","sourcesContent":["@import '~@portfolio-app/styles/src/properties/sizes';\n\n.AboutMe {\n  &_anchor {\n    top: -$base * 70;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AboutMe_anchor": `styles__AboutMe_anchor__UY0pc`
};
export default ___CSS_LOADER_EXPORT___;
