export const RU_LOCALE = {
  aboutMe: 'Обо мне',
  myBlog: 'Мой блог',
  myProjects: 'Тестовые задания',
  contactMe: 'Связь со мной:',
  gitVerseProfile: 'GitVerse репозиторий',
  telegramProfile: 'Профиль в Телеграм',
  mailMe: 'Электронная почта',
  vkMe: 'Профиль ВКонтакте',
  greetingsHeader: 'Здравствуйте, меня зовут Герман',
  messageSended: 'Сообщение отправлено!',
  messageSendFail: 'Сообщение не было отправлено :(',
  footerContent: 'Никитин Герман. Frontend разработчик.',
  aboutMeText:
    'Добро пожаловать на мое приложение-портфолио!\n' +
    'Меня зовут Никитин Герман и я senior frontend разработчик.\n \n' +
    'Этот веб-сайт - микросервисное одностраниченое приложение! ' +
    'Вы можете посмотреть исходный код данного приложения на моем GitVerse аккаунте. Это монорепозиторий.\n \n' +
    'Здесь вы можете узнать о моем опыте разработки и связаться со мной, если вас заинтересовало мое резюме. ' +
    'Кстати, его можно скачать прямо здесь в любом удобном для вас формате или распечатать на английском или русском языке. \n \n' +
    'Буду признателен за любую обратную связь. Хорошего вам дня!',
  agileCatBotInfo:
    'Создатель и мэйнтейнер AgileCatBot.\nЭто Телеграм онлайн чат-бот для организации кросс-ревью Agile команд',
  getAcb: 'Получить бота',
  checkAcb: 'Исходный код на GitVerse',
  downloadMyCv: 'Скачать резюме',
  printMyCv: 'Распечатать резюме',
  birthdateText: 'День рождения',
  birthDateInfo: '4 Августа, 1994',
  locationText: 'Место жительства',
  locationPlace: 'Санкт-Петербург, Россия',
  educationText: 'Образование',
  educationInfo: 'Университет ИТМО, Россия, 2010-2019',
  mySkills: 'Мои навыки',
  webDevelopment: 'Веб Разработка',
  webDevelopmentDescription:
    'Разработка веб-сайтов, одностраниченых приложений (SPA), чат ботов. Опыт работы с зарубежными клиентами (UpWork/oDesk) и крупными \n' +
    'компаниями (Альфа Банк, ВТБ, Газпром) в команде по Agile. Работаю только удалённо.',
  webDevelopmentMainDirections: 'Основные направления',
  webDevelopmentSkillReact: 'Разработка на React + Redux (SPA), опыт 6 лет (реализовано более 18 прод. приложений)',
  webDevelopmentSkillLayout:
    'Разработка сайтов на HTML5 / CSS3 / JavaScript + CMS WordPress / Joomla / Drupal / Bitrix с нуля.',
  webDevelopmentSkillIS: 'Разработка систем безопасности предприятия, анализ угроз.',
  frontEndDevelopment: 'Frontend Разработка',
  frontEndDevelopmentDescription: 'Уверенное знание современных методов и технологий frontend разработки',
  frontEndDevelopmentMainSkills: 'Ключевые навыки',
  frontEndDevelopmentSkillJS:
    'JavaScript и TypeScript с учетом современных спецификаций ES6-ES12, React, Redux (FLUX) + опыт работы с библиотеками Saga/Thunk.' +
    ' Глубокое понимание языка TypeScript с учетом продвинутых типов, юнионов и выборки из них (type guards), условных типов (conditional types), перегрузок (overloads),' +
    ' утилитарных типов, включая кастомные',
  frontEndDevelopmentSkillHTML:
    'Продвинутые знания современной верстки: HTML, CSS с пре/пост-процессорами (LESS / SASS / PostCSS) + Flex/Grid',
  frontEndDevelopmentSkillWebpack:
    'Понимание современных методов сборки проектов. Опыт работы с WebPack 4/5 (включая новый метод Module Federation), также опыт работы с большими монорепозиториями в продакшене (свыше 40 проектов, а также собственные библиотеки и вложения)',
  mySkillsLevel: 'Уровень знания технологий',
  myExperience: 'Мой опыт работы',
  myExperienceDescription1: 'Я занимаюсь коммерческой разработкой уже более',
  myExperienceDescription2: 'лет. Начинал с создания\nсайтов с нуля в веб студиях, в 2014 году.',
  myExperienceDescription3:
    'Последний год я работал с крупными компаниями в т.ч. международных проектах ' +
    '(ВТБ, ГазПром, Альфа Банк),\n в командах разработки (6-14 чел.)',

  workExperienceWebStudioPosition: 'Веб-мастер',
  workExperienceWebStudioCompanyName: 'Веб студия "Экса Медиа"',
  workExperienceWebStudioDescription:
    'Разработка, доработка, настройка и тех. поддержка сайтов (HTML5, javscript, php) пользователей на различных CMS.',

  workExperiencePrivateCompanyPosition: 'Web Разработчик',
  workExperiencePrivateCompanyCompanyName: 'iLocked',
  workExperiencePrivateCompanyDescription:
    'Разработка, продвижение и тех. поддержка сайта компании и франшизных проектов. Разработка высоконагруженного проекта под частной CMS.',

  workExperienceMobilePosition: 'Разработчик мобильных и веб-приложений',
  workExperienceMobileCompanyName: 'SHIFT Motors',
  workExperienceMobileDescription:
    'Разработка сайта компании (html/php/javascript), участие в стартапе: разработка android приложения,с использованием knockout.',

  workExperienceIpPosition: 'Frontend Web Разработчик',
  workExperienceIpCompanyName: 'ИП Никитин Герман Николаевич',
  workExperienceIpYears: '2017-2022',
  workExperienceIpDescription:
    'Разработка веб-сайтов, SPA. Опыт работы с зарубежными клиентами (UpWork/oDesk) и крупными компаниями (Альфа Банк, ВТБ, Газпром) в команде по Agile. В настоящее время реализовано более 8 продакшен проектов.',
  workExperienceCurrentPosition: 'Ведущий разработчик клиентских приложений',
  workExperienceCurrentName: 'ООО Центр Орбита',
  workExperienceCurrentYears: '2022-Настоящее время',
  workExperienceCurrentDescription:
    'Разработка микросервисного мультиплатформенного приложения для обслуживания клиентов компании в сфере финтех (крупный банк)',
  myEducation: 'Мое образование',
  myEducationUniversityFac: 'Безопасные Информационные Технологии',
  myEducationUniversityName: 'Университет ИТМО, Россия',
  myEducationUniversityDescription:
    'Тема диплома: Разработка безопасной информационной среды для коммерческой компании',
  myEducationUniversityFac2: 'Программное обеспечение вычислительной техники и автоматизированных систем',
  myEducationUniversityDescription2:
    'Тема диплома: ' +
    'Разработка программного модуля каталогизации учебных материалов для образовательной среды университета.',
  myEducationCoursesFac: 'Курсы предпрофильной подготовки',
  myEducationCoursesName: 'Институт Информационных Технологий СЕГРИС, Россия',
  sendMeMessage: 'Оставьте свое сообщение!',
  sendMeMessageDesc1: 'Вы разрабытваете или начинаете разработку своего приложения? \nБуду рад вам в этом помочь!',
  sendMeMessageDesc2:
    'Просто отправьте мне сообщение, используя контактную форму и я свяжусь с вами как можно скорее. \n\nСпасибо ;)',
  formNameLabel: 'Ваше имя',
  formEmailLabel: 'Ваш адрес электронной почты',
  formMessageLabel: 'Ваше сообщение',
  formSubmitButtonText: 'Отправить сообщение',

  projectsTimerName: 'Приложение "Таймер"',
  projectsTimerDescription:
    'Просто таймер на React, с использованием стилей и компонентов Material UI\n.' +
    'Можно установить интервал, сбросить таймер или поставить его на паузу.',
  homeLink: 'Главная',
  openProject: 'Открыть проект',
  projectsTaskDashboard: 'Доска задач с функцией DnD',
  projectsTaskDashboardDescription:
    'Настоящая доска задач с функцией drag and drop, как в Jira или Miro.\n' +
    'Вы можете перестаскивать элементы(задачи) между колонками без нарушения рабочего процесса задач. Например, вы можете перенести задачу из "TODO" в "DEV", но не можете из "TODO" в "PROD".',
  projectsUsersTable: 'Таблица пользователей',
  projectsUsersTableDescription:
    'Простая таблица пользователей. Список предварительно загружается с сервера (мок данные) Вы можете выбрать одного или несколько пользователей, которые будут отображаться списком в футере таблицы.',
  projectsQuiz: 'Приложение "Квиз"',
  projectQuizDescription:
    'Настоящий квиз с результатами после прохождения! Пройдите этот квиз и узнайте ваш результат!',
};
