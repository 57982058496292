// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__personalInfo__photo__ajYbg{min-height:550px}.styles__personalInfo__contacts__AWoBK{margin-top:12px}#styles__personalInfo__photo__ajYbg{background-position:75% 0}`, "",{"version":3,"sources":["webpack://./src/pages/MainPage/components/PersonalInfo/styles.scss","webpack://./../../libraries/styles/src/properties/spacing.scss"],"names":[],"mappings":"AAGE,oCACE,gBAAA,CAGF,uCACE,eCJM,CDSR,oCACE,yBAAA","sourcesContent":["@import '~@portfolio-app/styles/src/properties/spacing';\n\n.personalInfo {\n  &__photo {\n    min-height: $base * 137.5;\n  }\n\n  &__contacts {\n    margin-top: $space_s;\n  }\n}\n\n#personalInfo {\n  &__photo {\n    background-position: 75% 0;\n  }\n}\n","@import \"./sizes.scss\";\n\n$space_2xs: $base; // 4px\n$space_xs: 2 * $base; // 8px\n$space_s: 3 * $base; // 12px\n$space_m: 4 * $base; // 16px\n$space_l: 5 * $base; // 20px\n$space_xl: 6 * $base; // 24px\n$space_2xl: 8 * $base; // 32px\n$space_3xl: 10 * $base; // 40px\n$space_4xl: 12 * $base; // 48px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"personalInfo__photo": `styles__personalInfo__photo__ajYbg`,
	"personalInfo__contacts": `styles__personalInfo__contacts__AWoBK`
};
export default ___CSS_LOADER_EXPORT___;
