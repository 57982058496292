export * from './routesMap';
export * from './contacts';

export const GOOGLE_API_KEY = '6Lc4ON0ZAAAAAChfha6lfvsxoVoW4R-JYxI7oepa';

export const EMAIL_JS = {
  serviceId: 'progryx-web-mail-ru',
  templateId: 'template_f97pyh9',
  userId: '1gtEBrhlqXa1KFfer',
};
