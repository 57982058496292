// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__projectItem__X9ytW{width:100%}.styles__projectItem__preview__KSmf9{min-height:300px}`, "",{"version":3,"sources":["webpack://./src/pages/ProjectsPage/components/ProjectItem/styles.scss"],"names":[],"mappings":"AAEA,4BACE,UAAA,CAEA,qCACE,gBAAA","sourcesContent":["@import '~@portfolio-app/styles/src/properties/sizes';\n\n.projectItem {\n  width: 100%;\n\n  &__preview {\n    min-height: $base * 75;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectItem": `styles__projectItem__X9ytW`,
	"projectItem__preview": `styles__projectItem__preview__KSmf9`
};
export default ___CSS_LOADER_EXPORT___;
