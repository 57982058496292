// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles__desktopMenu__items__ekPAs{display:flex;flex:1;margin-left:16px}.styles__desktopMenu__items__ekPAs a,.styles__desktopMenu__items__ekPAs button{margin-right:8px}`, "",{"version":3,"sources":["webpack://./src/components/Layout/components/DesktopMenu/styles.scss","webpack://./../../libraries/styles/src/properties/spacing.scss"],"names":[],"mappings":"AAGE,mCACE,YAAA,CACA,MAAA,CACA,gBCDM,CDGN,+EAEE,gBCPK","sourcesContent":["@import '~@portfolio-app/styles/src/properties/spacing';\n\n.desktopMenu {\n  &__items {\n    display: flex;\n    flex: 1;\n    margin-left: $space_m;\n\n    a,\n    button {\n      margin-right: $space_xs;\n    }\n  }\n}\n","@import \"./sizes.scss\";\n\n$space_2xs: $base; // 4px\n$space_xs: 2 * $base; // 8px\n$space_s: 3 * $base; // 12px\n$space_m: 4 * $base; // 16px\n$space_l: 5 * $base; // 20px\n$space_xl: 6 * $base; // 24px\n$space_2xl: 8 * $base; // 32px\n$space_3xl: 10 * $base; // 40px\n$space_4xl: 12 * $base; // 48px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktopMenu__items": `styles__desktopMenu__items__ekPAs`
};
export default ___CSS_LOADER_EXPORT___;
