export * from './icons/css3';
export * from './icons/gmail';
export * from './icons/graphQl';
export * from './icons/html5Icon';
export * from './icons/javascript';
export * from './icons/react';
export * from './icons/redux';
export * from './icons/restApi';
export * from './icons/sass';
export * from './icons/github';
export * from './icons/skype';
export * from './icons/webpack';
export * from './icons/telegram';
export * from './icons/typescript';
export * from './icons/vk';
export * from './icons/gitverse';
